<template>
  <v-footer>
    <v-row class="text-center">
      <v-col> Derechos reservados a Katal &reg; </v-col>
      <v-col>version {{ version }} ({{modo}})</v-col>
    </v-row>
  </v-footer>
</template>

<script>
export const VERSIOM = process.env.VUE_APP_VERSION;
export const NODE_ENV = process.env.NODE_ENV;

export default {
  data: () => ({ version: VERSIOM, modo: NODE_ENV }),
  methods: {},
};
</script>