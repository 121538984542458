<template>
  <v-navigation-drawer v-model="drawer" color="indigo-lighten-2" v-if="login">
    Dashboard Montos<br>
    Dashboard Gráficos<br>
    Carreras<br>
    Combustible<br>
    Pagos conductor<br>
    Gastos Vehículo<br>
  </v-navigation-drawer>

  <v-app-bar color="cyan-darken-2">
    <v-app-bar-nav-icon
      @click="drawer = !drawer"
      v-if="login"
    ></v-app-bar-nav-icon>
    <v-app-bar-title> Sistema de gestión de flota</v-app-bar-title>
    <v-spacer></v-spacer>
    <p class="mr-4">{{ nombre }}</p>
    <v-btn
      variant="outlined"
      elevation="5"
      color="black"
      prepend-icon="mdi-login"
      v-if="!login"
      href="./login"
    >
      Ingresar
    </v-btn>
    <v-btn
      variant="flat"
      color="orange-lighten-2"
      append-icon="mdi-logout"
      v-if="login"
      @click="cerrarSesion()"
    >
      Salir
    </v-btn>
  </v-app-bar>
</template>
  
  <script>
import { ref } from "vue";


export default {
  data: () => ({ drawer: false}),
  props: {
    login: Boolean,
    nombre: String,
  },
  methods: {
    cerrarSesion: function () {
      this.$router.push({ path: "/" });
      this.$emit("changeLogin", false, "");
    },
  },
};
</script>