import { createStore } from 'vuex'
import autenticacion from './autenticacion'

export default createStore({
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: { 
    autenticacion
  }
})
