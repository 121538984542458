<template>
  <v-container fluid>
    <v-app>
      <MenuTop
        :login="login"
        :nombre="nombre"
        @changeLogin="ChangeLogin"
        @CerrarSesion="CerrarSesion"
      ></MenuTop>
      <v-main>
        <v-container class="fondo" fluid>
          <router-view
            :login="login"
            @changeLogin="ChangeLogin"
            @CerrarSesion="CerrarSesion"
          />
        </v-container>
      </v-main>
      <Footer></Footer>
    </v-app>
  </v-container>
</template>

<script>

import MenuTop from "./components/menu/top.vue";
import Footer from "./components/menu/footer.vue";

/**
 * FTP:
 * usuario: rmachuca@transporteqa.katal.cl
 * clave: @M?axcg*$f.^
 */

export default {
  components: {
    MenuTop,
    Footer,
  },
  name: "App",

  data: () => ({
    //
    usuario: localStorage.getItem("Usuario"),
    login: localStorage.getItem("Autenticado"),
    nombre: localStorage.getItem("nombre"),
    appName: "prueba de sistema",
  }),
  methods: {
    ChangeLogin: function (NewLogin, nombre) {
      this.login = NewLogin;
      this.nombre = nombre;
      if (this.login) {
        localStorage.setItem("Autenticado", NewLogin);
        localStorage.setItem("nombre", nombre);
      } else {
        this.nombre = "";
        localStorage.clear();
      }
    },
    CerrarSesion: function () {
      this.login = false;
      this.nombre = "";
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.fondo {
  background: url("../public/FondoFull.gif") no-repeat center center;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-color: white;
}
</style>