<template>
  <v-container>
    <v-row class=" d-flex justify-center align-center">
      <v-col cols="12">
        <v-img
          src="require('../../public/Logo480.gif')"
          class="my-3"
          contain
          height="300"
        />
      </v-col>

      <v-col class="mb-4" cols="8" mb="12">
        <h1 class="display-2 font-weight-bold mb-3 text-center">
          Bienvenidos al sistema de gestión de flotas KATAL
        </h1>
        <p class="subheading font-weight-regular">
          En el competitivo mundo del transporte privado de pasajeros, la eficiencia y la gestión inteligente son clave para el éxito. 
          Imagina tener el control total de tus carreras, pagos, gastos, y más, todo desde una plataforma informática integrada diseñada para simplificar tu operación y maximizar tus ganancias.
        </p><br>
        <h4 class="display-2 font-weight-bold mb-3">
          <v-btn density variant="plain" color="indigo" @click="crearCuenta()"  class="text-body-2 font-weight-regular">¡Creala tu nueva cuenta!</v-btn>
        </h4>
        <h3 class="display-2 font-weight-bold mb-3">
          ¿Por qué deberías considerar nuestro sistema informático para la gestión de carreras?
        </h3>
        <v-row>
          <v-col cols="12" md="6">
            <ul>
              <li><b>Eficiencia Operativa:</b><br>
                Asigna automáticamente carreras a conductores disponibles, optimizando la utilización de tu flota.<br>
                Minimiza tiempos de espera y mejora la satisfacción del cliente al ofrecer un servicio más rápido y eficiente.
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="6">
            <ul>
              <li><b>Control Total de Gastos:</b><br>
                Registra y categoriza fácilmente todos los gastos asociados con tu flota, desde combustible hasta mantenimiento.<br>
                Identifica áreas de oportunidad para reducir costos y maximizar la rentabilidad de cada carrera.
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="6">
            <ul>
              <li><b>Gestión de Pagos Simplificada:</b><br>
                Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual.<br>
                Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones..
              </li>
            </ul>
          </v-col>
          
          <v-col cols="12" md="6">
            <ul>
              <li><b>Control de TAG:</b><br>
                Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual.<br>
                Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones..
              </li>
            </ul>
          </v-col>
        </v-row><br> 
        <h4 class="display-2 font-weight-bold mb-3">
          <v-btn density variant="plain" color="indigo" @click="crearCuenta()"  class="text-body-2 font-weight-regular">¡Creala tu nueva cuenta!</v-btn>
        </h4>
        <h3 class="display-2 font-weight-bold mb-3">
          Beneficios Adicionales:
        </h3>
        <p class="subheading font-weight-regular">
          Mayor transparencia en los costos para conductores y operadores.<br>
          Análisis detallado del rendimiento operativo para una toma de decisiones informada.<br>
          Experiencia mejorada para los usuarios finales, con tiempos de espera reducidos y un servicio más confiable.<br>
          
        </p><br>
        <p class="subheading font-weight-regular">
          No te quedes atrás en la revolución digital del transporte privado. Nuestra plataforma informática está diseñada para llevar tu negocio al siguiente nivel, ofreciendo eficiencia, control financiero y una ventaja competitiva. ¡Haz el cambio hoy y experimenta la transformación en la gestión de carreras de transporte privado!
        </p><br/><br/>
        <p>
          <h3  class="display-2 font-weight-bold mb-3">
          Módulos y Funcionalidades Principales:
          </h3></p>
        <p class="subheading font-weight-regular">
          <h4  class="display-2 font-weight-bold mb-3">
            a. Gestión de Carreras:
          </h4>
          <p>
            <ul>
              <li>Información detallada sobre horarios, servicio, convenio, tarifas de las carreras por cada conductor.</li>
              <li>Respaldo en imagen de los vales que se haya emitido en la carrera.</li>
              <li>Observaciones que puedan particularizar cualquier información relevante con la carrera en cuestion.</li>
            </ul>
          </p>
        </p><br>
        <p class="subheading font-weight-regular">
          <h4  class="display-2 font-weight-bold mb-3">
            b. Control de TAG:
          </h4>
          <p>
            <ul>
              <li>Información detallada de los TAG que se utilizan durante el uso de vehículos.</li>
              <li>Tener actualizado y en línea los gastos de TAG de su empresa.</li>
              <li>Cargar los TAG al sistema utilizando directamene los archivos Excel o CSV que entrega cada autopista, permitiendo así, tener un detalle exausito de estos, con fecha, portico y monto del mismo.</li>
              <li>Inteligencia para determinar si un peaje corresponde está o no dentro de una carrera.</li>
              <li>Inteligencia para determinar si un peaje corresponde está o no dentro del turno de un conductor.</li>
            </ul>
          </p>
        </p><br>
        <p class="subheading font-weight-regular">
          <h4  class="display-2 font-weight-bold mb-3">
            C. Registro de Gastos:
          </h4>
          <p>
            <ul>
              <li>Información detallada de los TAG que se utilizan durante el uso de vehículos.</li>
              <li>Tener actualizado y en línea los gastos de TAG de su empresa.</li>
              <li>Cargar los TAG al sistema utilizando directamene los archivos Excel o CSV que entrega cada autopista, permitiendo así, tener un detalle exausito de estos, con fecha, portico y monto del mismo.</li>
              <li>Inteligencia para determinar si un peaje corresponde está o no dentro de una carrera.</li>
              <li>Inteligencia para determinar si un peaje corresponde está o no dentro del turno de un conductor.</li>
            </ul>
          </p>
        </p><br>

        <!--a. Gestión de Carreras:
        - Registro y asignación automatizada de carreras a conductores disponibles.
        - Información detallada sobre el origen, destino, horarios y tarifas de las carreras.
        - Historial de carreras para llevar un registro completo de las transacciones.

        b. Control de TAG:
        - Integración con sistemas de peaje y TAG para un pago automático y seguimiento eficiente.
        - Alertas y notificaciones para mantener un monitoreo en tiempo real de los gastos asociados.

        c. Procesamiento de Pagos:
        - Pasarelas de pago seguras para la facturación automatizada a los usuarios.
        - Registro automático de pagos y generación de recibos electrónicos.

        d. Registro de Gastos:
        - Herramienta para ingresar y categorizar gastos relacionados con el vehículo y la operación.
        - Seguimiento detallado de gastos como mantenimiento, reparaciones y otros costos asociados.

        e. Gastos de Combustible:
        - Registro automatizado de las transacciones de combustible.
        - Análisis de eficiencia de combustible y alertas sobre variaciones en los precios.

        f. Control de Gastos Generales del Vehículo:
        - Registro de gastos generales como seguros, impuestos y depreciación.
        - Generación de informes detallados sobre los costos totales asociados a cada vehículo.

        3. Beneficios del Sistema:

        a. Eficiencia Financiera:
        - Reducción de errores y pérdidas gracias a la automatización de procesos de pago y registro de gastos.
        - Mayor visibilidad de los costos operativos para una toma de decisiones informada.

        b. Optimización de Recursos:
        - Asignación eficiente de carreras y gestión de flotas para maximizar la rentabilidad.
        - Identificación de áreas de mejora en términos de eficiencia de combustible y mantenimiento.

        c. Cumplimiento y Transparencia:
        - Cumplimiento fácil con requisitos fiscales y regulatorios gracias a la generación automática de informes.
        - Transparencia en los costos para conductores y operadores del servicio.

        4. Tecnologías Utilizadas:

        Integración de APIs de sistemas de peaje y TAG.
        Pasarelas de pago electrónico seguras.
        Sistemas de seguimiento y gestión de flotas.
        Herramientas analíticas para el análisis de eficiencia y costos.
        La Plataforma Integrada de Gestión de Transporte de Pasajeros ofrece una solución completa para abordar los aspectos logísticos y económicos del transporte privado de pasajeros, garantizando una gestión eficiente, transparente y rentable de las operaciones.
        -->

        <p class="subheading font-weight-regular">
          <!--Este sistema ha sido desarrollado para apoyar la gestión y control de flotas de vehiculos de transportes de pasajeros.<br>
          Katal es un sistema de autogestión de servicios de pasajeros, donde podre controlar puntos importantes como valor de carrera, cargas de combustibles, <br>
          TAG reales en autopiestas, entregas y uso de efectivos, pagos a conductores según producción, ingresos, egresos, utilidades, gastos, y mucho mas.-->
          <br>Para ponerse en contacto escribanos a 
          <a
            href="mailto:contacto@transporte.katal.cl"
          >contacto@transporte.katal.cl</a>
        </p>

        <!--
          En el competitivo mundo del transporte privado de pasajeros, la eficiencia y la gestión inteligente son clave para el éxito. Imagina tener el control total de tus carreras, pagos, gastos, y más, todo desde una plataforma informática integrada diseñada para simplificar tu operación y maximizar tus ganancias.

¿Por qué deberías considerar nuestro sistema informático para la gestión de carreras?

Eficiencia Operativa:

Asigna automáticamente carreras a conductores disponibles, optimizando la utilización de tu flota.
Minimiza tiempos de espera y mejora la satisfacción del cliente al ofrecer un servicio más rápido y eficiente.
Control Total de Gastos:

Registra y categoriza fácilmente todos los gastos asociados con tu flota, desde combustible hasta mantenimiento.
Identifica áreas de oportunidad para reducir costos y maximizar la rentabilidad de cada carrera.
Gestión de Pagos Simplificada:

Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual.
Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones.
Optimización de Rutas:

Planifica rutas más eficientes, minimizando el tiempo de viaje y maximizando la capacidad de carga.
Adapta las rutas en tiempo real según las condiciones del tráfico para evitar demoras.
Cumplimiento Regulatorio:

Cumple fácilmente con requisitos fiscales y regulatorios gracias a la generación automática de informes detallados.
Mantén registros digitales actualizados para auditorías y cumplimiento normativo.
Beneficios Adicionales:

Mayor transparencia en los costos para conductores y operadores.
Análisis detallado del rendimiento operativo para una toma de decisiones informada.
Experiencia mejorada para los usuarios finales, con tiempos de espera reducidos y un servicio más confiable.
No te quedes atrás en la revolución digital del transporte privado. Nuestra plataforma informática está diseñada para llevar tu negocio al siguiente nivel, ofreciendo eficiencia, control financiero y una ventaja competitiva. ¡Haz el cambio hoy y experimenta la transformación en la gestión de carreras de transporte privado!
        -->
      </v-col>

      <!--v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          What's next?
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          Important Links
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          Ecosystem
        </h2>

        <v-row justify="center">
          <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-row>
      </v-col-->
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'login',

  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader/tree/next',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify/tree/next',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
    importantLinks: [
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com',
      },
      {
        text: 'Roadmap',
        href: 'https://vuetifyjs.com/introduction/roadmap/',
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
      },
    ],
  }),
  methods: {
    crearCuenta: function(){
      this.$router.push({ path: "/nuevoUsuario" });
    },
  }
}
</script>
