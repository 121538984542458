import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },{
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginUser.vue')
  },{
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/dashboard.vue')
  },{
    path: "/menus",
    name: "menus",
    component: () => import(/* webpackChunkName: "about" */ '../views/menus.vue')
  }
  ,{
    path: "/carreras",
    name: "carreras",
    component: () => import(/* webpackChunkName: "about" */ '../views/carreras/listaCarreras.vue')
  }
  ,{
    path: "/combustible",
    name: "combustible",
    component: () => import(/* webpackChunkName: "about" */ '../views/combustible/listarCargas.vue')
  }
  ,{
    path: "/chartCarrera",
    name: "chartCarrera",
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/ChartCarrera.vue')
  }
  ,{
    path: "/listaPagoCond",
    name: "listaPagoCond",
    component: () => import(/* webpackChunkName: "about" */ '../views/pagoconductor/listaPagoConductor.vue')
  }
  ,{
    path: "/olvideContrasena",
    name: "olvideContrasena",
    component: () => import(/* webpackChunkName: "about" */ '@/views/olvideContrasena.vue')
  }
  ,{
    path: "/nuevoUsuario",
    name: "nuevoUsuario",
    component: () => import(/* webpackChunkName: "about" */ '@/views/nuevaCuenta/nuevoUsuario.vue')
  }
  ,{
    path: "/tag",
    name: "TAG",
    component: () => import(/* webpackChunkName: "about" */ '@/views/TAG/listadoTAG.vue')
  }
  ,{
    path: "/gasto",
    name: "Gasto",
    component: () => import(/* webpackChunkName: "about" */ '@/views/Gasto/listadoGasto.vue')
  },{
    path: "/entregaefectivo",
    name: "EntregaEfectivo",
    component: () => import(/* webpackChunkName: "about" */ '@/views/entregaefectivo/listaentregaefectivo.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
