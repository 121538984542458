<template>
  <homeKatal/>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import homeKatal from './homeKatal.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    homeKatal,
  },
});
</script>
