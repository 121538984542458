
export default{
  namespaced: true,
  state: {
    Items: []
  },
  getters: {
    Items(state){
      return state.Items;
    }
  },
  mutations: {
    llenarItems(state, data){
      state.Items = data;
    }
  },
  actions: {
    autenticar: async function({commit}){
      try {
        var data2 = {
          login: 'username',
          password: 'password'
        }
        const setting = {
          method: 'POST',
          body: JSON.stringify(data2)
        }
        //const url = 'https://picsum.photos/v2/list?page=2&limit=100';
        const url = 'http://localhost/apikataltransporte/usuarios/usuarios';
        const data = await fetch(url, setting);
        const json = await data.json();
        commit('llenarItems',json);
      }
      catch (err){
        console.log(err);
      }
    }
  }
}
